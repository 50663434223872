<template>
  <b-card-actions
    action-collapse
    title="Daftar Rak"
  >
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          @click.prevent="add"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal
        v-model="showModal"
          id="modal"
          cancel-variant="secondary"
          ok-title="Submit"
          cancel-title="Batal"
          centered
          title="Form Rak"
        >
          <b-form>
            <b-form-group>
              <label for="palet">Pallet</label>
              <b-form-select
                        v-model="form.palet_id"
                        :options="palet_id"
                        label="Pallet"
                      />
            </b-form-group>
            <b-form-group>
              <label for="blok">Blok</label>
              <b-form-select
              disabled
                        v-model="form.blok_id"
                        :options="blok_id"
                        label="Blok"
                      />
            </b-form-group>
            <b-form-group>
              <label for="gudang">Gudang</label>
              <b-form-select
                        disabled
                        v-model="form.gudang_id"
                        :options="gudang_id"
                        label="Gudang"
                      />
            </b-form-group>
            <b-form-group>
              <label for="rak">Nama Rak</label>
              <b-form-input v-model="form.rak" id="rak" placeholder="Nama Rak" />
            </b-form-group>
            
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="success" @click="submit">
                Simpan
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModal = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
        
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          
        >
        <template #cell(no)="row">
            <strong class="text-center">
              {{ (row.index + 1) }}
            </strong>
          </template>

          <template #cell(rak_id)="data">
            <b-badge variant="primary">
              {{ getBlokName(data.item)  }}
            </b-badge>
          </template>

          <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="edit(row.item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.right="'Hapus'"
                  size="sm"
                  @click="remove(row.item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,ToastificationContent,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox ,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal : false,
      id:null,
      form: {
        blok_id : null,
        rak : null,
        palet_id: null,
        gudang_id : null,
      },
      blok_id:[],
      gudang_id :[],
      palet_id:[],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        { key: "gudang.nama_gudang", label: "Gudang", sortable: true },
        { key: "blok.blok", label: "Blok", sortable: true },
        { key: "palet.palet", label: "Pallet", sortable: true },
        { key: "rak", label: "Rak", sortable: true },
        // { key: "kendaraan", label: "Kendaraan"},
        // { key: "sales_id", label: "Sales"},
        // { key: "delete", label: "Hapus"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData()
    this.getDatablok()
    this.getDataGudang()
    this.getDatapalet()

  },
  watch: {
    "form.palet_id"(val) {
      if(val) {
        const currentPalet = this.palet_id.find(palet => palet.id == val && palet.gudang)
        if(currentPalet) {
          this.form.gudang_id = currentPalet.gudang.id
          this.form.blok_id = currentPalet.blok.id
        }
      }
    }
  },
  methods: {
    add() {
      this.id = null
      this.resetForm()
      this.activeAction = 'tambah'
      this.showModal = true
    },
    remove(item ){
      this.$swal({
        title: 'Anda yakin?',
        text: `Data rak ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('rak/save', [item])
          .then(() => {
            this.getData()
            this.pesanBerhasilHapus()
          })
          .catch(e => {
            this.pesanGagalHapus()
            this.displayError(e)
            return false
          })
        }
      })
    },
    resetForm() {
      this.id = null
      delete this.form.id
      this.form.palet_id = null
      this.form.blok_id = null
      this.form.gudang_id = null
      this.form.rak = null
    },
    submit() {
      if(this.form.rak == null || this.form.rak == '') {
        this.pesanGagalSimpan()
        return false
      }
      if(this.id) {
            this.form.id = this.id
          }
          const payload = this.form
          this.$store.dispatch('rak/save', [payload])
          .then(() => {
          this.id = null
          this.getData()
          this.pesanBerhasilSimpan()
          this.resetForm()
          this.showModal = false
        })
      .catch(e => console.error(e))
      // this.pesanGagalSimpan()
    },
    edit(item) {
      let {id, palet, blok, gudang, rak} = item
      this.id = item.id
      this.form.id = id
      this.form.rak = rak
      if (palet) {
        this.form.palet_id = palet.id;
      }
      if (blok) {
        this.form.blok_id = blok.id;
      }
      if (gudang) {
        this.form.gudang_id = gudang.id;
      }
      this.showModal = true
    },
    pesanBerhasilSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menyimpan data',
          variant: 'success',
        },
      },
      {
        position:'bottom-right',
      })
    },
    pesanGagalSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menyimpan data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
      {
        position:'bottom-right',
      })
    },
    pesanBerhasilHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menghapus data',
          variant: 'success',
        },
      },
      {
        position:'bottom-right',
      })
    },
    pesanGagalHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menghapus data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
      {
        position:'bottom-right',
      })
    },
    getData() {
      let params = {}
      if(this.isAdminGudang) {
        params.gudang_id = this.myGudang.id
      }
      this.$store.dispatch('rak/getData', params)
      .then(() => {
        this.items = this.$store.state.rak.datas
        this.totalRows = this.items.length;
      })
      .catch(e => console.error(e))
    },
    getDatablok() {
      let params = {
        jenis: 1
      }
      if(this.isAdminGudang) {
        params.gudang_id = this.myGudang.id
      }
      this.$store.dispatch('blok/getData', params)
      .then(() => {
        let ter = JSON.parse( JSON.stringify(this.$store.state.blok.datas) )
        ter.map(item => {
          item.value = item.id
          item.text = item.blok
        })
        this.blok_id = ter
      })
    },
    getBlokName(data) {
      let find = this.blok_id.find(item => item.id == data.blok_id)
      return find ? find.blok : '-'
    },
    getDatapalet() {
      let params = {
      }
      if(this.isAdminGudang) {
        params.gudang_id = this.myGudang.id
      }
      this.$store.dispatch('pallet/getData', params)
      .then(() => {
        let ter = JSON.parse( JSON.stringify(this.$store.state.pallet.datas) )
        ter.map(item => {
          item.value = item.id
          item.text = item.palet + " - " + item.blok.blok
        })
        this.palet_id = ter
      })
    },
    getpaletName(data) {
      let find = this.palet_id.find(item => item.id == data.palet_id)
      return find ? find.palet : '-'
    },
    getDataGudang() {
      this.$store.dispatch('gudang/getData', {})
      .then(() => {
        let ter = JSON.parse( JSON.stringify(this.$store.state.gudang.datas) )
        ter.map(item => {
          item.value = item.id
          item.text = item.nama_gudang
        })
        this.gudang_id = ter
      })
    },
    getGudangName(data) {
      let find = this.gudang_id.find(item => item.id == data.gudang_id)
      return find ? find.nama_gudang : '-'
    },

    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
